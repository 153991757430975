import React from "react";
import { Link } from "react-router-dom";

const TopHeader = (props) => {
  const { topBarClass, emailAddress, phoneNumber, Location, oldUrl } = props;

  return (
    <div
      className={
        topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
      }
    >
      <div className="container">
        <div className="row y-middle">
          <div className="col-md-8">
            <ul className="topbar-contact">
              {emailAddress ? (
                <li className="mr-4 ml-4">
                  <i className="flaticon-email mr-4 ml-4"></i>
                  <a href={"mailto:" + emailAddress}>{emailAddress}</a>
                </li>
              ) : (
                <li className="mr-4 ml-4">
                  <i className="flaticon-email mr-4 ml-4"></i>
                  <a href="mailto:info@zu.edu.ly">info@zu.edu.ly</a>
                </li>
              )}
              {phoneNumber ? (
                <li className="mr-4 ml-4">
                  <i className="flaticon-call mr-4 ml-4"></i>
                  <a href={"tel:+" + phoneNumber}>{phoneNumber}</a>
                </li>
              ) : (
                <li className="mr-4 ml-4">
                  <i className="flaticon-call mr-4 ml-4"></i>
                  <a href="tel:+(218) 23-762682">+(218) 23-762682</a>
                </li>
              )}
              {Location ? (
                <li>
                  <i className="flaticon-location"></i>
                  {Location}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          {/* <div className="col-md-4 text-start">
            <ul className="topbar-right ">
              <li className="login-register"></li>
              <li className="btn-part">
                <a
                  href={oldUrl ? oldUrl : "http://zu.edu.ly/university"}
                  className="apply-btn"
                  target="_blank"
                >
                  الاصدار القديم للموقع
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
